<template>
  <div>
  <ul class="flex flex-wrap space-y-2 md:space-y-0 md:space-x-2 my-1">
    <li v-for="(scale, index) in filterScales" :key="`scale-${index}`">

      <a href="" @click.prevent="emitMark(scale)" class="font-semibold rounded px-2 py-1 flex hover:bg-gray-100 text-sm"
        :class="
          row.id === scale.id
            ? isAssesment
              ? 'bg-blue-600 text-white border-white'
              : 'bg-green-400 text-white border-white'
            : isAssesment
              ? 'text-blue-600'
              : 'text-green-400'
        ">{{ scale.name }} - {{ scale.description }}</a>
    </li>
  </ul>

  <div v-show="this.toggledmark.includes('Keine Bewertung') ? true : false">
    <label class="flex flex-wrap md:space-x-2 my-1 mt-3 font-semibold text-sm text-gray-700">
      Massnahme
    </label>
    <select @input="emitEvaluationStatus($event)"
      v-model="evaluation_status"
      class="mt-1 mb-2 block w-full rounded-md border border-gray-200 py-2 pl-3 pr-10 text-base sm:text-sm">
      <option disabled selected >Massnahme auswählen</option>
      <option>Ziel für die nächste Beurteilungsperiode</option>
      <option>Ziel wurde gestoppt/abgebrochen</option>
    </select>
  </div>

  <label v-show="this.evaluation_status == 'Ziel wurde gestoppt/abgebrochen'"
    class="flex flex-wrap space-y-2 md:space-y-0 md:space-x-2 my-1 font-semibold text-sm mt-1 text-gray-700">
    Grund
  </label>
</div>

</template>

<script>
import _ from 'lodash'

export default {
  name: 'rating-scales',
  props: ['value', 'isAssesment', 'scales', 'toggle', 'evaluation_status_value',],
  data() {
    return {
      filtered_scale: _.cloneDeep(this.scales),
      toggled: false,
      toggledmark: '',
      evaluation_status: '',
      row: { ...this.value },
    }
  },
  mounted() {
    this.init()
  },
  computed: {
    filterScales() {
      if (this.isAssesment == true & this.toggled == true) {
        return this.scales
      } else {
        _.remove(this.filtered_scale, { description: "Keine Bewertung möglich" });
        return this.filtered_scale
      }
    }
  },
  methods: {
    init() {
      this.evaluation_status_value ? this.evaluation_status = this.evaluation_status_value : this.evaluation_status = 'Massnahme auswählen'
      this.toggle ? this.toggled = true : this.toggled = false
      
      // responsible to expand if mark "Keine Bewertung möglich" is toggled to display the dropdown menu
      let findScale = _.filter(this.scales, {id: this.row.id})
      if (findScale && findScale[0]) {
        findScale[0].description && findScale[0].description.includes('Keine Bewertung möglich')
          ? this.toggledmark = 'Keine Bewertung möglich'
          : ''
      }
    },
    emitMark(scale) {
      this.row = scale
      this.$emit('input', this.row.id)
      this.$emit('mark', this.row.name)
      this.$emit('evaluationstatus', '')

      this.toggledmark = this.row.description

    },
    emitEvaluationStatus($event) {
      this.evaluation_status = $event.target.value
      this.$emit('evaluationstatus', this.evaluation_status)
    },
  },
  watch: {
    value: {
      handler: function (n) {
        this.row.id = n
      },
      immediate: true,
    },
  },
}
</script>

<style>

</style>
